import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WDR_CONSTANT } from '../../../constants/wdr.constant';
import { DynamicReportsUtilityService } from '../../services/dynamic-reports-utility.service';
import { ISelectionConfig } from '../../interface/common.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class SelectListComponent {
  @Input() selectFormGroup: FormGroup;
  @Input() options: any[] = [];
  @Input() selectionConfig: ISelectionConfig;
  @Output() selectionChange = new EventEmitter();

  fieldRequiredMessage = WDR_CONSTANT.fieldRequiredMessage;
  noSearchResultMessage = WDR_CONSTANT.noSearchResultMessage;
  noOptionsMessage = WDR_CONSTANT.noSearchResultMessage;
  noItemsFound = WDR_CONSTANT.noItemsFound;

  combinedDisplay = [];
  filterText = '';
  filteredOptions = [];
  optionKeys = [];
  selectedOptions = [];
  tableHeader = [];
  columnWidth = [];
  alignText = [];

  get selectionFormControl() {
    return this.selectFormGroup?.controls;
  }

  constructor(private readonly dynamicReportsUtilityService: DynamicReportsUtilityService) {}

  ngOnChanges() {
    this.refineOptions();
    this.getOptionKeys();
    this.resetFilterOptions();
  }

  ngOnInit() {
    this.setDefaultSelectionConfig();
    this.onValueChangeFormControl();
    this.resetFilterOptions();
  }

  // Listen to the update of the Display Text when setting a value from the parent component
  onValueChangeFormControl() {
    if (this.selectionConfig?.controlName) {
      this.selectionFormControl[this.selectionConfig.controlName]?.valueChanges?.subscribe(() => {
        this.checkSelectedOptionExists();
      });
    }
  }

  refineOptions() {
    if (this.options && this.options.length === 1 && this.options[0] === null) {
      this.options = [];
    }
  }

  setDefaultSelectionConfig() {
    const defaultSelectionConfig = {
      placeHolder: 'Select',
      enableSearch: false,
      enableMultiSelection: false,
      matOptionLimit: WDR_CONSTANT.matOptionLimit,
    };
    this.selectionConfig = { ...defaultSelectionConfig, ...this.selectionConfig };
  }

  checkDisplayObjRef() {
    if (!this.selectionConfig.displayObjRef?.length && this.selectionConfig.valueObjRef) {
      this.combinedDisplay = this.filteredOptions.map((option: any) => {
        return Object.values(Object.fromEntries(Object.entries(option).filter((key) => key[0] !== this.selectionConfig.valueObjRef))).join('-');
      });
    } else {
      // if option is list of string or number
      this.combinedDisplay = this.filteredOptions;
    }
  }

  resetFilterOptions() {
    this.filteredOptions = this.dynamicReportsUtilityService.deepCopy(this.options);
    this.limitFilterOptions();
  }

  getOptionKeys() {
    if (this.selectionConfig?.tableConfig?.length) {
      this.setTableConfiguration();
    } else if (this.options?.length && typeof this.options[0] === 'object') {
      this.optionKeys = Object.keys(this.options[0]).filter((option) => this.selectionConfig.valueObjRef !== option);
    }
  }

  setTableConfiguration() {
    if (this.selectionConfig?.tableConfig?.length) {
      this.resetTableConfiguration();
      this.selectionConfig.tableConfig.forEach((config) => {
        this.tableHeader.push(config['header']);
        this.optionKeys.push(config['valueRef']);
        this.columnWidth.push(config['width'] ? config['width'] : WDR_CONSTANT.defaultMatOptionColumnWidth);
        this.alignText.push(config['textAlign'] ? config['textAlign'] : '');
      });
    }
  }
  resetTableConfiguration() {
    this.tableHeader = [];
    this.optionKeys = [];
    this.columnWidth = [];
    this.alignText = [];
  }

  limitFilterOptions() {
    if (this.selectionConfig.enableSearch) {
      this.filteredOptions?.splice(this.selectionConfig.matOptionLimit);
    }
    this.checkDisplayObjRef();
    this.checkSelectedOptionExists();
  }

  checkSelectedOptionExists() {
    let fieldValue;
    if (this.selectionFormControl) {
      fieldValue = this.selectionFormControl[this.selectionConfig?.controlName]?.value;
    }
    let selectedValues = [];
    this.selectedOptions = [];

    if (fieldValue) {
      selectedValues = typeof fieldValue === 'object' && this.selectionConfig.enableMultiSelection ? [...fieldValue] : [fieldValue];
    }

    if (selectedValues?.length) {
      selectedValues.forEach((selectedValue) => {
        let selectedOption = selectedValue;
        if (this.selectionConfig.valueObjRef) {
          selectedOption = this.dynamicReportsUtilityService.findCollections({
            collections: this.options,
            keyRef: this.selectionConfig.valueObjRef,
            compareValue: selectedValue,
          });
        }
        this.selectedOptions.push(selectedOption);
        if (selectedOption && !this.dynamicReportsUtilityService.objectIncludesInCollection(this.filteredOptions, selectedOption)) {
          this.filteredOptions.push(selectedOption);
        }
      });
    }
  }

  filterOptions() {
    const filterValue = this.filterText.toLowerCase();
    if (!filterValue) {
      this.resetFilterOptions();
      return;
    }
    this.filteredOptions = this.filterLogic(filterValue);
    this.limitFilterOptions();
  }

  filterLogic(filterValue: string) {
    let filteredOptions = [];
    if (this.options?.length && typeof this.options[0] === 'object') {
      filteredOptions = this.options.filter((option) =>
        this.optionKeys.some((optionKey) => {
          return option[optionKey] ? option[optionKey].toString().toLowerCase().includes(filterValue) : false;
        })
      );
    } else if (this.options?.length) {
      filteredOptions = this.options.filter((option) => option.toString().toLowerCase().includes(filterValue));
    }
    return filteredOptions;
  }

  closeSelect() {
    this.filterText = '';
    this.resetFilterOptions();
    this.triggerSelection();
  }

  triggerSelection() {
    if (this.selectionChange && this.selectedOptions?.length) {
      const selection = {
        value: this.selectionFormControl[this.selectionConfig.controlName]?.value,
        selectedOptions: this.selectedOptions,
        controlName: this.selectionConfig.controlName,
        enableMultiSelection: this.selectionConfig.enableMultiSelection,
      };
      this.selectionChange.emit(selection);
    }
  }

  getCustomDisplayName() {
    if (this.selectionConfig.customDisplayObj) {
      return this.selectedOptions.map((option) => option[this.selectionConfig.customDisplayObj]).join(', ');
    } else if (this.selectedOptions?.length && typeof this.selectedOptions[0] === 'object') {
      return this.selectedOptions
        .map((option: any) => {
          if (!this.selectionConfig.displayObjRef?.length || this.selectionConfig.tableConfig) {
            return Object.values(
              Object.fromEntries(Object.entries(option).filter((key) => key[0] !== this.selectionConfig.valueObjRef && this.optionKeys.includes(key[0])))
            ).join(' ');
          } else {
            return option[this.selectionConfig.displayObjRef?.join(' ')];
          }
        })
        .join(', ');
    } else {
      return this.selectedOptions.join(', ');
    }
  }

  displayMultipleObj(filteredOption, selectionConfig) {
    const displayData = [];
    selectionConfig?.displayObjRef?.forEach((element: any) => {
      displayData.push(filteredOption[element]);
    });
    return displayData?.join(' - ');
  }
}
