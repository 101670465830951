import { Component } from '@angular/core';
import { ViewReportExportPdfService } from '../../services/view-report-export-pdf.service';
import { ViewReportExportExcelService } from '../../services/view-report-export-excel.service';
import { ViewReportService } from '../../services/view-report.service';
import { IGetReportTableResponse } from '../../interface/view-report.interface';
import { IMyReports } from '../../../../modules/my-reports/interface/my-reports.interface';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss'],
})
export class ReportContentComponent {
  tableHeader = [];
  tableData = [];
  rowColor = [];
  viewReportTableData: IGetReportTableResponse[];
  viewReportDetails: IMyReports;
  viewFilteredData;
  reportObject: any = {};

  constructor(
    public viewReportService: ViewReportService,
    private readonly exportPdfService: ViewReportExportPdfService,
    private readonly exportExcelService: ViewReportExportExcelService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService
  ) {}

  ngOnInit() {
    this.viewReportTableData = this.viewReportService.viewReportTableData;
    this.viewReportDetails = this.viewReportService.viewReportDetails;
    this.viewFilteredData = this.viewReportService.viewFilteredData;
    this.reportObject = {
      title: this.viewReportDetails?.report_title,
      date: this.viewFilteredData?.date?.value,
      tableCaption: this.joinSelectedItem(),
    };
    this.setReportTableConfig();
  }

  joinSelectedItem() {
    const propertiesToJoin = ['region_code', 'district_code', 'dealer_code', 'dealer_name'];
    let joinedString = '';
    for (const prop of propertiesToJoin) {
      joinedString += this.viewFilteredData?.item?.selectedOptions[0][prop] + '-';
    }
    joinedString = joinedString.substring(0, joinedString.length - 1);
    return joinedString;
  }

  setReportTableConfig() {
    this.viewReportTableData?.forEach((tConfig) => {
      if (!this.tableHeader.length) {
        tConfig.rows.forEach((rowData) => {
          this.tableHeader.push(rowData.column_name);
        });
      }
    });
  }

  getExportDocumentInfo() {
    const [monthInNumber, year] = this.reportObject?.date?.split('/') || 0;
    const monthInString = this.dynamicReportsUtilityService.getMonthName(monthInNumber);
    return { ...this.reportObject, date: `${monthInString} ${year}` };
  }

  exportToExcel() {
    const reportDataForExcel = this.getExportDocumentInfo();
    this.exportExcelService.exportToExcel(reportDataForExcel);
  }

  exportToPdf() {
    const reportDataForPDF = this.getExportDocumentInfo();
    this.exportPdfService.exportToPdf(reportDataForPDF);
  }
}
