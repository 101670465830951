<div class="dxfw-wdr-view-report-container" *ngIf="viewReportDetails">
  <div class="dxfw-wdr-view-report-header">
    <div class="dxfw-wdr-view-report-title">{{ viewReportDetails.report_title }}</div>
    <button class="secondary-button back-to-report" (click)="backToReport()">
      <mat-icon>keyboard_double_arrow_left</mat-icon> Back To {{ isSharedReport ? 'Shared Reports' : 'My Reports' }}
    </button>
  </div>
  <div>
    <mat-sidenav-container>
      <mat-sidenav #sidenav [opened]="true" [mode]="'side'" position="start">
        <app-report-filter [sideNav]="sidenav" (emitReportData)="emittedReportData($event)"></app-report-filter>
      </mat-sidenav>
      <mat-sidenav-content>
        <app-report-content *ngIf="isAnyReportDataAvailable()"></app-report-content>
        <div class="noTableData VH-center50" *ngIf="viewReportService.viewReportTableData && !isAnyReportDataAvailable()">
          {{ constant.WDR_CONSTANT.noRecordsAvailable }}
        </div>
        <div class="noTableData VH-center50" *ngIf="!viewReportService.viewReportTableData">
          {{ constant.WDR_CONSTANT.selectOptionMessage }}
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
