<aside class="dxfw-wdr-view-report-aside" [class.expanded]="expandedSideNav">
  <h4 (click)="handleSideNavToggle()" (keydown)="handleSideNavToggle()">Filter</h4>
  <div class="toggle top" (click)="handleSideNavToggle()" (keydown)="handleSideNavToggle()">
    <em class="tmi" [class.tmi-arrow-double-left]="expandedSideNav" [class.tmi-arrow-double-right]="!expandedSideNav"></em>
  </div>
  <div *ngIf="expandedSideNav" class="dxfw-wdr-mat-select-container">
    <div *ngFor="let formGroup of reportFilterFormGroups; let i = index" [hidden]="i !== currentStep">
      <form [formGroup]="formGroup" *ngIf="reportFilterDropdownResponse" class="dxfw-wdr-form-container">
        <div *ngIf="isStackedReport()" class="report-header d-flex">
          <h2 class="report-name" [attr.title]="stackedReportDetails[i]?.report_name">{{ stackedReportDetails[i]?.report_name }}</h2>
          <span class="page-index-badge">{{ currentStep + 1 }} of {{ reportFilterFormGroups.length }}</span>
        </div>
        <div class="row splitter">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <label for="tagInput" class="wdr-label">
              Date
              <span class="clearBtnWrapper">
                <span class="clearBtn" (click)="onClickClearBtn(formGroup, 'date')" (keydown)="onClickClearBtn(formGroup, 'date')">Clear</span>
                <mat-icon (click)="onClickExpandBtn(i, 'Date')" (keydown)="onClickExpandBtn(i, 'Date')">
                  {{ toggleFormControls[i].toggleDate ? 'keyboard_arrow_down' : 'keyboard_control_key' }}
                </mat-icon>
              </span>
            </label>
          </div>
          <div class="col-md-12 clearBtn m-b-10" *ngIf="toggleFormControls[i].toggleDate">
            <app-select-list
              [selectFormGroup]="formGroup"
              [options]="reportFilterDropdownResponse[i]?.date"
              [selectionConfig]="selectionConfigsByReportType[i].dateFilterConfig"
              (selectionChange)="selectedData(i, 'date', $event)"></app-select-list>
          </div>
        </div>
        <div class="row splitter m-t-15">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <label for="tagInput" class="wdr-label">
              Item
              <span class="clearBtnWrapper">
                <span class="clearBtn" (click)="onClickClearBtn(formGroup, 'item')" (keydown)="onClickClearBtn(formGroup, 'item')">Clear</span>
                <mat-icon (click)="onClickExpandBtn(i, 'Item')" (keydown)="onClickExpandBtn(i, 'Item')">
                  {{ toggleFormControls[i].toggleItem ? 'keyboard_arrow_down' : 'keyboard_control_key' }}
                </mat-icon>
              </span>
            </label>
          </div>
          <div class="col-md-12 clearBtn m-b-10" *ngIf="toggleFormControls[i].toggleItem">
            <app-select-list
              [selectFormGroup]="formGroup"
              [options]="reportFilterDropdownResponse[i]?.item"
              [selectionConfig]="selectionConfigsByReportType[i].itemFilterConfig"
              (selectionChange)="selectedData(i, 'item', $event)"></app-select-list>
          </div>
        </div>
        <div class="row splitter m-t-15" *ngIf="templateId === constant.TEMPLATE_ID.EXTERNAL_COMPARISON">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <label for="tagInput" class="wdr-label">
              Comparator
              <span class="clearBtnWrapper">
                <span class="clearBtn" (click)="onClickClearBtn(formGroup, 'comparator')" (keydown)="onClickClearBtn(formGroup, 'comparator')">Clear</span>
                <mat-icon (click)="onClickExpandBtn(i, 'Comparator')" (keydown)="onClickExpandBtn(i, 'Comparator')">
                  {{ toggleFormControls[i].toggleComparator ? 'keyboard_arrow_down' : 'keyboard_control_key' }}
                </mat-icon>
              </span>
            </label>
          </div>
          <div class="col-md-12 clearBtn m-b-10" *ngIf="toggleFormControls[i].toggleComparator">
            <app-select-list
              [selectFormGroup]="formGroup"
              [options]="reportFilterDropdownResponse[i]?.comparator_criteria"
              [selectionConfig]="selectionConfigsByReportType[i].comparatorFilterConfig"
              (selectionChange)="selectedData(i, 'comparator', $event)"></app-select-list>
          </div>
        </div>
      </form>
    </div>
    <div>
      <div class="navigation-buttons" *ngIf="isStackedReport()">
        <button class="primary-button action small-button" mat-button (click)="prevStep()" [disabled]="currentStep === 0">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button class="primary-button action small-button" mat-button (click)="nextStep()" [disabled]="currentStep === reportFilterFormGroups.length - 1">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
      <button class="primary-button action small-button right-btn" [disabled]="!areAllFormsValid()" (click)="onSearch()">Search</button>
    </div>
  </div>
</aside>
