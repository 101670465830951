<div class="report-content-container">
  <div class="export-icon">
    <mat-icon class="icon" (click)="exportToPdf()">picture_as_pdf</mat-icon>
    <svg (click)="exportToExcel()" class="icon" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M14 2H6a2 2 0 0 0-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8zm4 18H6V4h7v5h5zm-5.1-5.5l2.9 4.5H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 10H10l2 3.4l2-3.4h1.8z"></path>
    </svg>
  </div>
  <div class="report-title">
    {{ reportObject?.tableCaption }}
  </div>
  <table aria-describedby="dxfw-report-content-table" id="dxfw-wdr-view-report-table">
    <thead>
      <tr>
        <th *ngFor="let header of tableHeader; index as headerIndex" class="dxfw-wdr-view-report-header-cell" [ngClass]="{ 'align-right': headerIndex > 1 }">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of viewReportTableData" [ngClass]="data.level_id.toString()">
        <td *ngFor="let rowData of data.rows; index as dataIndex" class="dxfw-wdr-view-report-body-cell" [ngClass]="{ 'align-right': dataIndex > 1 }">
          {{ rowData?.format !== 'currency' ? rowData.value : (rowData.value | currency) }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
