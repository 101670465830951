import { Injectable } from '@angular/core';
import { IMyReports, IMyReportStackedReportDetails } from '../../my-reports/interface/my-reports.interface';
import { DynamicReportsHttpService } from '../../../shared/services/dynamic-reports-http.service';
import { Observable } from 'rxjs';
import { DATASOURCE_CONFIG, DYNAMIC_REPORTS_API_URLS, TEMPLATE_ID } from '../../../constants/wdr.constant';
import { CONFIG_CONSTANTS, HIERARCHY_TABLE_CONFIG, LEVEL_CONFIG } from '../../../constants/build-reports-stepper.constant';
import { DROPDOWN_CONFIG } from '../../../constants/dropdown-config.constant';
import { IGetReportFilterRequest, IGetReportFilterResponse, IGetSearchRequestParamsByReportType } from '../interface/view-report.interface';

@Injectable({
  providedIn: 'root',
})
export class ViewReportService {
  private reportDetails: IMyReports;
  private isReportShared: boolean;
  private reportTableData;
  private filterData;

  constructor(private readonly dynamicReportsHttpService: DynamicReportsHttpService) {}

  set viewReportDetails(reportDetails: IMyReports) {
    this.reportDetails = reportDetails;
  }

  get viewReportDetails(): IMyReports {
    return this.reportDetails;
  }

  set isSharedReport(isReportShared: boolean) {
    this.isReportShared = isReportShared;
  }

  get isSharedReport(): boolean {
    return this.isReportShared;
  }

  setFilterItem(dropdownControl) {
    for (const field in CONFIG_CONSTANTS) {
      DROPDOWN_CONFIG.REPORT_FILTER_CONTROL[dropdownControl][CONFIG_CONSTANTS[field]] =
        LEVEL_CONFIG?.[this.viewReportDetails.level_id]?.[CONFIG_CONSTANTS[field]];
    }
  }

  set viewReportTableData(tableResponse) {
    this.reportTableData = tableResponse;
  }

  get viewReportTableData() {
    return this.reportTableData;
  }

  set viewFilteredData(filterData) {
    this.filterData = filterData;
  }

  get viewFilteredData() {
    return this.filterData;
  }

  getDealerTableConfig(dropdownControl, levelId, hierarchyId) {
    if (dropdownControl === 'ITEM_CONTROL' && levelId === 10) {
      if (HIERARCHY_TABLE_CONFIG.hasOwnProperty(hierarchyId)) {
        LEVEL_CONFIG[levelId].tableConfig = HIERARCHY_TABLE_CONFIG[hierarchyId].tableConfig;
        LEVEL_CONFIG[levelId].displayObjRef = HIERARCHY_TABLE_CONFIG[hierarchyId].displayObjRef;
      }
    }
  }

  getTemplateIdByReportId(reportId?: number) {
    if (this.viewReportDetails.template_id === TEMPLATE_ID.STACK) {
      const foundReport = this.viewReportDetails.stackedReportDetails.find((report: IMyReportStackedReportDetails) => report.sub_report_id === reportId);
      return foundReport ? foundReport.template_id : null;
    }
    return this.viewReportDetails?.template_id;
  }

  getReportFilter(reqParams: IGetReportFilterRequest): Observable<IGetReportFilterResponse> {
    return this.dynamicReportsHttpService.getForkJoin(DYNAMIC_REPORTS_API_URLS().GET_REPORT_FILTER, reqParams);
  }

  getAllReportsFilterApiCalls(apiCalls) {
    return this.dynamicReportsHttpService.makeParallelApiCalls(apiCalls);
  }

  getAllReportsViewOrTableData(apiCalls) {
    return this.dynamicReportsHttpService.makeParallelApiCalls(apiCalls);
  }

  getViewReport(reqParams: IGetSearchRequestParamsByReportType) {
    // Sample API Endpoint - https://dxfw-api.dev.dealer.toyota.com/dynamicreports/hierarchical-financial-report
    const templateId = this.getTemplateIdByReportId(reqParams.report_id);
    const datasource = DATASOURCE_CONFIG[templateId]?.datasource[reqParams.datasource_id];
    const template = DATASOURCE_CONFIG[templateId]?.name;
    return this.dynamicReportsHttpService.getForkJoin(
      DYNAMIC_REPORTS_API_URLS().GET_VIEW_REPORT.replace('{template-name}', template).replace('{datasource_name}', datasource),
      reqParams
    );
  }
}
