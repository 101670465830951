<ng-container *ngIf="selectFormGroup && selectionConfig.controlName" [formGroup]="selectFormGroup">
  <mat-form-field class="dd-mat-mdc-select-form-field">
    <mat-select
      #select
      [formControlName]="selectionConfig.controlName"
      disableRipple
      class="dd-mat-mdc-select full-width"
      [panelClass]="'dxfw-wdr-select-panel ' + selectionConfig.panelClass"
      [multiple]="selectionConfig.enableMultiSelection"
      [placeholder]="selectionConfig.placeHolder"
      (selectionChange)="checkSelectedOptionExists()"
      (closed)="closeSelect()"
      [title]="getCustomDisplayName()">
      <mat-select-trigger *ngIf="selectionConfig.customDisplayObj">
        {{ getCustomDisplayName() }}
      </mat-select-trigger>
      <!-- Search Filter input -->
      <ng-container *ngIf="options && options.length && selectionConfig.enableSearch">
        <div class="select-search-container">
          <mat-form-field class="dd-mat-mdc-input-form-field full-width">
            <input
              matInput
              #filterSelect
              class="select-search-input"
              placeholder="Search"
              [(ngModel)]="filterText"
              [ngModelOptions]="{ standalone: true }"
              (keyup)="filterOptions()"
              (keydown)="$event.stopPropagation()" />
            <button mat-icon-button class="close-button" *ngIf="filterSelect.value && filteredOptions" (click)="closeSelect()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
      <!-- Dropdown with table layout -->
      <ng-container *ngIf="tableHeader.length; else withoutTable">
        <div class="select-table">
          <div class="select-table-header">
            <div class="table-column" *ngIf="selectionConfig.enableMultiSelection" [ngStyle]="{ 'width.px': '30' }">&nbsp;</div>
            <ng-container *ngFor="let header of tableHeader; let i = index">
              <div class="table-column" [ngStyle]="{ 'width.px': columnWidth[i], 'text-align': alignText[i]}" [title]="header">{{ header }}</div>
            </ng-container>
          </div>
          <div class="select-table-body">
            <ng-container *ngIf="filteredOptions && filteredOptions.length; else emptyFilter">
              <mat-option
                *ngFor="let filteredOption of filteredOptions"
                [value]="selectionConfig.valueObjRef ? filteredOption[selectionConfig.valueObjRef] : filteredOption">
                <div class="select-table-row">
                  <ng-container *ngFor="let optionKey of optionKeys; let i = index">
                    <div class="table-column" [ngStyle]="{ 'width.px': columnWidth[i], 'text-align': alignText[i] }" [title]="filteredOption[optionKey]">
                      {{ filteredOption[optionKey] }}
                    </div>
                  </ng-container>
                </div>
              </mat-option>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- Dropdown without table layout -->
      <ng-template #withoutTable>
        <mat-option *ngIf="!selectionConfig.enableMultiSelection">{{ selectionConfig.placeHolder }}</mat-option>
        <!-- filteredOptions are available -->
        <ng-container *ngIf="filteredOptions && filteredOptions.length; else emptyFilter">
          <mat-option
            *ngFor="let filteredOption of filteredOptions; let i = index"
            [value]="selectionConfig.valueObjRef ? filteredOption[selectionConfig.valueObjRef] : filteredOption">
            {{ selectionConfig?.displayObjRef ? displayMultipleObj(filteredOption,selectionConfig) : combinedDisplay[i] }}
          </mat-option>
        </ng-container>
      </ng-template>
      <!-- Search filter result is empty -->
      <ng-template #emptyFilter>
        <div *ngIf="options && options.length; else emptyOptions" class="empty-option-layout">{{ noSearchResultMessage }}</div>
      </ng-template>
      <!-- No options available for dropdown -->
      <ng-template #emptyOptions>
        <div class="empty-option-layout">{{ noOptionsMessage }}</div>
      </ng-template>
    </mat-select>
    <!-- error message -->
    <mat-error *ngIf="(options && options.length) && selectFormGroup && selectFormGroup.get(selectionConfig?.controlName)?.hasError('required')">{{ fieldRequiredMessage }}</mat-error>
  </mat-form-field>
</ng-container>
