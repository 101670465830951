<aside [class.expanded]="expandedSideNav">
  <h4 (click)="handleSideNavToggle()">Filter</h4>
  <div class="toggle top" (click)="handleSideNavToggle()">
    <em class="tmi" [class.tmi-arrow-double-left]="expandedSideNav" [class.tmi-arrow-double-right]="!expandedSideNav"></em>
  </div>
  <div *ngIf="expandedSideNav" class="dxfw-wdr-mat-select-container">
    <form [formGroup]="reportFilterFormGroup" *ngIf="reportFilterDropdownResponse" class="dxfw-wdr-form-container">
      <div class="row splitter">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="tagInput" class="wdr-label">
            Date
            <span class="clearBtnWrapper">
              <span class="clearBtn" (click)="onClickClearBtn('date')">Clear</span>
              <mat-icon (click)="onClickExpandBtn('Date')">{{ reportFilterDate ? 'keyboard_arrow_down' : 'keyboard_control_key' }}</mat-icon>
            </span>
          </label>
        </div>
        <div class="col-md-12 clearBtn m-b-10" *ngIf="reportFilterDate">
          <app-select-list
            [selectFormGroup]="reportFilterFormGroup"
            [options]="reportFilterDropdownResponse?.date"
            [selectionConfig]="dateFilterConfig" (selectionChange)="selectedData('date',$event)"></app-select-list>
        </div>
      </div>
      <div class="row splitter m-t-15">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="tagInput" class="wdr-label">
            Item
            <span class="clearBtnWrapper">
              <span class="clearBtn" (click)="onClickClearBtn('item')">Clear</span>
              <mat-icon (click)="onClickExpandBtn('Item')">{{ reportFilterItem ? 'keyboard_arrow_down' : 'keyboard_control_key' }}</mat-icon>
            </span>
          </label>
        </div>
        <div class="col-md-12 clearBtn m-b-10" *ngIf="reportFilterItem">
          <app-select-list
            [selectFormGroup]="reportFilterFormGroup"
            [options]="reportFilterDropdownResponse?.item"
            [selectionConfig]="itemFilterConfig"
            (selectionChange)="selectedData('item',$event)"></app-select-list>
        </div>
      </div>
      <div class="row splitter m-t-15" *ngIf="templateId === constant.TEMPLATE_ID.EXTERNAL_COMPARISON">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="tagInput" class="wdr-label">
            Comparator
            <span class="clearBtnWrapper">
              <span class="clearBtn" (click)="onClickClearBtn('comparator')">Clear</span>
              <mat-icon (click)="onClickExpandBtn('Comparator')">{{ reportFilterComparator ? 'keyboard_arrow_down' : 'keyboard_control_key' }}</mat-icon>
            </span>
          </label>
        </div>
        <div class="col-md-12 clearBtn m-b-10" *ngIf="reportFilterComparator">
          <app-select-list
            [selectFormGroup]="reportFilterFormGroup"
            [options]="reportFilterDropdownResponse?.comparator_criteria"
            [selectionConfig]="comparatorFilterConfig"
            (selectionChange)="selectedData('comparator',$event)"></app-select-list>
        </div>
      </div>
    </form>
    <div>
      <button class="primary-button action small-button right-btn" [disabled]="!reportFilterFormGroup.valid" (click)="onSearch()">Search</button>
    </div>
  </div>
</aside>
