<form [formGroup]="reportCriteriaFormGroup" (click)="showTooltip = false" (keydown)="showTooltip = false">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 m-b-10" *ngIf="getReportTemplateId() === templateId.EXTERNAL_COMPARISON">
      <h3>DEALER CRITERIA:</h3>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Data Source</label>
    </div>
    <div class="col-md-12">
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Data Source'"
          formControlName="dataSource"
          required
          disableRipple
          class="dd-mat-mdc-select full-width"
          panelClass="dxfw-wdr-select-panel"
          (selectionChange)="onSelectionChange('dataSource')">
          <mat-option>Select Data Source</mat-option>
          <mat-option *ngFor="let option of dataSourceOptions" value="{{ option.id }}">
            {{ option.datasource_name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportCriteriaFormGroup.get('dataSource')?.hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label float-left">Hierarchy Type</label>
      <mat-icon class="float-right cursor-pointer" (click)="$event.stopPropagation(); displayTooltip($event)" (keydown)="$event.stopPropagation()"
        >info_outline</mat-icon
      >
    </div>
    <div class="col-md-12">
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Hierarchy Type'"
          formControlName="hierarchyType"
          required
          disableRipple
          class="dd-mat-mdc-select full-width"
          panelClass="dxfw-wdr-select-panel"
          (selectionChange)="onSelectionChange('hierarchyType')">
          <mat-option>Select Hierarchy Type</mat-option>
          <mat-option *ngFor="let option of hierarchyTypeOptions" value="{{ option.hierarchy_id }}">
            {{ option.hierarchy_name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportCriteriaFormGroup.get('hierarchyType')?.hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Hierarchy Level</label>
    </div>
    <div class="col-md-12">
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Hierarchy Level'"
          formControlName="hierarchyLevel"
          required
          disableRipple
          class="dd-mat-mdc-select full-width"
          panelClass="dxfw-wdr-select-panel"
          (selectionChange)="onSelectionChange('hierarchyLevel')">
          <mat-option>Select Hierarchy Type</mat-option>
          <mat-option *ngFor="let option of hierarchyLevelOptions" value="{{ option.hierarchy_level_id }}">
            {{ option.hierarchy_level_name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportCriteriaFormGroup.get('hierarchyLevel')?.hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-t-20">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Company</label>
    </div>
    <div class="col-md-12">
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Company'"
          formControlName="company"
          required
          disableRipple
          class="dd-mat-mdc-select full-width"
          panelClass="dxfw-wdr-select-panel"
          (selectionChange)="onSelectionChange('company')">
          <mat-option>Select Hierarchy Type</mat-option>
          <mat-option *ngFor="let option of companyOptions" value="{{ option.company_id }}">
            {{ option.company_name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportCriteriaFormGroup.get('company')?.hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row m-t-20" *ngIf="getReportTemplateId() !== templateId.HISTORICAL">
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Time Period</label>
    </div>
    <div class="col-md-12">
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Time Period'"
          formControlName="timePeriod"
          required
          disableRipple
          class="dd-mat-mdc-select full-width"
          panelClass="dxfw-wdr-select-panel"
          (selectionChange)="onSelectionChange('timePeriod')">
          <mat-option>Select Hierarchy Type</mat-option>
          <mat-option *ngFor="let option of timePeriodOptions" value="{{ option.id }}">
            {{ option.time_period_name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportCriteriaFormGroup.get('timePeriod')?.hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- Hierarchical Report -->
  <div class="row m-t-20" *ngIf="getReportTemplateId() === templateId.HIERARCHICAL">
    <div class="col-sm-12 col-md-12 col-lg-12 dxfw-wdr-checkbox">
      <mat-checkbox formControlName="showAllDealers">Show All Dealers</mat-checkbox>
      <div class="mat-checkbox-hint">
        Shows all dealers in the region with no district rollups. This is most useful at the National, Company, Region, and Area Levels.
      </div>
    </div>
  </div>
  <div class="row m-t-20" *ngIf="getReportTemplateId() === templateId.HIERARCHICAL">
    <div class="col-sm-12 col-md-12 col-lg-12 dxfw-wdr-checkbox">
      <mat-checkbox formControlName="showTerminatedDealers">Show Terminated Dealers</mat-checkbox>
      <div class="mat-checkbox-hint">Includes terminated dealers in the report.</div>
    </div>
  </div>
  <!-- External Comparison Report -->
  <div class="row m-t-25" *ngIf="getReportTemplateId() === templateId.EXTERNAL_COMPARISON">
    <div class="col-sm-12 col-md-12 col-lg-12 m-b-10">
      <h3>COMPARATOR CRITERIA:</h3>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <label for="tagInput" class="wdr-stepper-label">Hierarchy Level</label>
    </div>
    <div class="col-md-12">
      <mat-form-field class="dd-mat-mdc-select-form-field">
        <mat-select
          [placeholder]="'Select Hierarchy Level'"
          formControlName="comparatorCriteriaHierarchyLevel"
          required
          disableRipple
          class="dd-mat-mdc-select full-width"
          panelClass="dxfw-wdr-select-panel"
          (selectionChange)="onSelectionChange('comparatorCriteriaHierarchyLevel')">
          <mat-option>Select Hierarchy Type</mat-option>
          <mat-option *ngFor="let option of comparatorCriteriaHierarchyLevelOptions" value="{{ option.id }}">
            {{ option.comparator_criteria_name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reportCriteriaFormGroup.get('comparatorCriteriaHierarchyLevel')?.hasError('required')">{{ fieldRequiredErrorMessage }}</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- Historical Report -->
  <div class="row m-t-20" *ngIf="getReportTemplateId() === templateId.HISTORICAL">
    <div class="col-sm-12 col-md-12 col-lg-12 dxfw-wdr-checkbox">
      <mat-checkbox formControlName="showTotalColumn">DISPLAY TOTAL COLUMN</mat-checkbox>
    </div>
  </div>
</form>
<app-custom-tooltip
  [showTooltip]="showTooltip"
  [tooltipData]="tooltipData"
  [topPosition]="tooltipTopPosition"
  [leftPosition]="tooltipLeftPosition"
  [isTooltipTable]="isTooltipTable"></app-custom-tooltip>
