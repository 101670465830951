<div class="dxfw-report-column-container">
  <div class="available-column">
    <form [formGroup]="reportColumnFormGroup">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label class="wdr-report-column-title">Available Columns:</label>
          <label class="wdr-stepper-label">Maximum of {{ allowedReportFieldLimit }} fields can be added for the report</label>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="fieldName" class="wdr-stepper-label">Field Name</label>
        </div>
        <div class="col-md-12">
          <app-select-list
            [selectFormGroup]="reportColumnFormGroup"
            [options]="reportColumnDropdownResponse.field_name"
            [selectionConfig]="fieldNameConfig"
            (selectionChange)="mapDataSource($event)"></app-select-list>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="fieldCategory" class="wdr-stepper-label">Field Category</label>
        </div>
        <div class="col-md-12">
          <app-select-list
            [selectFormGroup]="reportColumnFormGroup"
            [options]="reportColumnDropdownResponse.category"
            [selectionConfig]="fieldCategoryConfig"
            (selectionChange)="mapDataSource($event)"></app-select-list>
        </div>
      </div>
      <div class="row m-t-20">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="timePeriod" class="wdr-stepper-label">Time Period</label>
        </div>
        <div class="col-md-12">
          <app-select-list
            [selectFormGroup]="reportColumnFormGroup"
            [options]="reportColumnDropdownResponse.timeperiod"
            [selectionConfig]="fieldTimePeriodConfig"
            (selectionChange)="mapDataSource($event)"></app-select-list>
        </div>
      </div>
      <div class="row m-t-20" *ngIf="getReportTemplateId() === templateId.COMPARISON">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="comparision" class="wdr-stepper-label">Comparison</label>
        </div>
        <div class="col-md-12">
          <app-select-list
            [selectFormGroup]="reportColumnFormGroup"
            [options]="reportColumnDropdownResponse.comparision"
            [selectionConfig]="comparisonConfig"
            (selectionChange)="mapDataSource($event)"></app-select-list>
        </div>
      </div>
      <div class="row m-t-20" *ngIf="getReportTemplateId() === templateId.EXTERNAL_COMPARISON">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="displayName" class="wdr-stepper-label">Display As</label>
        </div>
        <div class="col-md-12">
          <mat-form-field [floatLabel]="'auto'" class="dd-mat-mdc-input-form-field full-width">
            <input matInput placeholder="Enter Display Name" formControlName="displayName" (blur)="onBlurDisplayName()" />
            <mat-error *ngIf="reportColumnFormGroup.get('displayName').hasError('required')">{{ fieldRequiredMessage }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row m-t-20" *ngIf="getReportTemplateId() === templateId.EXTERNAL_COMPARISON">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <label for="comparatorQualifier" class="wdr-stepper-label">Comparator Qualifier</label>
        </div>
        <div class="col-md-12">
          <app-select-list
            [selectFormGroup]="reportColumnFormGroup"
            [options]="reportColumnDropdownResponse.comparatorQualifier"
            [selectionConfig]="comparatorQualifierConfig"
            (selectionChange)="mapDataSource($event)"></app-select-list>
        </div>
      </div>
      <div class="row m-t-20">
        <button
          mat-button
          type="submit"
          class="add-item m-r-10"
          (click)="validateAddItem()"
          [disabled]="selectedFieldsTableDataSource.length >= allowedReportFieldLimit">
          <mat-icon>add</mat-icon> Add Item
        </button>
        <button mat-button type="reset" (click)="resetItem()"><mat-icon>refresh</mat-icon> Reset</button>
      </div>
    </form>
  </div>
  <div class="selected-field m-t-20">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <label class="wdr-report-column-title">Selected Fields:</label>
        <label class="wdr-stepper-label">Click and drag a field to change its display order in the report</label>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-sm-12 col-md-12 col-lg-12 p-r-0">
        <mat-table
          #selectedFieldsTable
          [dataSource]="selectedFieldsTableDataSource?.length > 0 ? selectedFieldsTableDataSource : emptyData"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          cdkDropListData="selectedFieldsTableDataSource"
          [cdkDropListDisabled]="dragDisabled"
          multiTemplateDataRows>
          <ng-container matColumnDef="field_name" sticky>
            <mat-header-cell *matHeaderCellDef class="field-name-column">Field Name</mat-header-cell>
            <mat-cell *matCellDef="let element" class="field-name-column">
              <mat-icon class="dragCursor" (mousedown)="dragDisabled = false">reorder</mat-icon>
              <span>{{ element.field_name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="category_name">
            <mat-header-cell *matHeaderCellDef class="field-category-column">Field Category</mat-header-cell>
            <mat-cell *matCellDef="let element" class="field-category-column">
              <span>{{ element.category_name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="time_period_name">
            <mat-header-cell *matHeaderCellDef class="time-period-column">Time Period</mat-header-cell>
            <mat-cell *matCellDef="let element" class="time-period-column">
              <span>{{ element.time_period_name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="display_name">
            <mat-header-cell *matHeaderCellDef class="display-name-column">Display As</mat-header-cell>
            <mat-cell *matCellDef="let element" class="display-name-column">
              <span>{{ element.display_name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="comparison_name">
            <mat-header-cell *matHeaderCellDef class="comparison-column">
              {{ getReportTemplateId() === templateId.EXTERNAL_COMPARISON ? 'Comparator Qualifier' : 'Comparison' }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="comparison-column">
              <span>{{ element.comparison_name }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef class="action-column">Action</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = dataIndex" class="action-column">
              <button mat-icon-button matTooltip="Delete" (click)="deleteReportColumn(i)">
                <mat-icon class="delete-icon">delete_outline</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="empty-row">
            <mat-cell *matCellDef="let element" class="nodata">No Data Found</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="selectedFieldsTableColumn; sticky: true"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: selectedFieldsTableDataSource?.length > 0 ? selectedFieldsTableColumn : ['empty-row']"
            cdkDrag
            [cdkDragData]="row"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
