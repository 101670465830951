import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SHARE_REPORT_DIALOG_HEADER } from '../../../constants/build-reports-stepper.constant';
import { DynamicReportsUtilityService } from '../../services/dynamic-reports-utility.service';
import { DynamicReportsDialogService } from '../../services/dynamic-reports-dialog.service';
import { IGetUserDetails, IUserSearchedShareReport } from '../../interface/share-report-dialog.interface';
import { IMultiSelectOptions, ISelectionConfig } from '../../interface/common.interface';
import { MatSelectionList } from '@angular/material/list';
import { DROPDOWN_CONFIG } from '../../../constants/dropdown-config.constant';

@Component({
  selector: 'app-share-report-dialog',
  templateUrl: './share-report-dialog.component.html',
  styleUrls: ['./share-report-dialog.component.scss', '../build-reports-stepper-dialog/build-reports-stepper-dialog.component.scss'],
})
export class ShareReportDialogComponent {
  @ViewChild('prevSharedUsersRef') prevSharedUsersRef: MatSelectionList;
  @ViewChild('selectedUsersRef') selectedUsersRef: MatSelectionList;

  isLinear = false;
  stepperHeader = SHARE_REPORT_DIALOG_HEADER;
  searchUsersConfig: ISelectionConfig = DROPDOWN_CONFIG.SHARED_REPORT_CONTROL.SEARCH_USERS_CONTROL as ISelectionConfig;

  shareReportFormGroup = this._formBuilder.group({
    searchUsers: [],
  });

  prevSharedUsersCheckAll = false;
  selectedUsersCheckAll = false;

  usersOptions: IUserSearchedShareReport[] = [];
  usersMultiSelectOptions: IMultiSelectOptions[] = [];

  searchedUsersList: IUserSearchedShareReport[] = [];
  previouslySharedUsersList: IUserSearchedShareReport[] = [];
  selectedUsersList: IUserSearchedShareReport[] = [];

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly dynamicReportsDialogService: DynamicReportsDialogService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    public dialogRef: MatDialogRef<ShareReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.getUserDetails();
  }

  searchUsersValueChangeHandler(emittedObj): void {
    const userIds = emittedObj.value || [];
    this.searchedUsersList = [];
    if (userIds && userIds.length > 0) {
      this.updateSearchedUsersList(userIds);
    }
  }

  updateSearchedUsersList(userIds: string[]): void {
    userIds.forEach((userId) => {
      if (!this.searchedUsersList.some((existingUser) => existingUser.user_id === userId)) {
        this.searchedUsersList.push(this.usersOptions.find((existingUser) => existingUser.user_id === userId));
      }
    });
  }

  getUserDetails(): void {
    const reqParams = {
      users_prev_shared: true,
      get_search_user: true,
    };
    if (this.modalData.reportId) {
      reqParams['report_id'] = this.modalData.reportId;
    }
    this.dynamicReportsDialogService.getUserDetailsToShareReport(reqParams).subscribe({
      next: (responseData: IGetUserDetails) => {
        this.getUserDetailsToShareReportHandler(responseData);
      },
      error: this.errorHandler.bind(this),
    });
  }

  getUserDetailsToShareReportHandler(responseData): void {
    this.usersOptions = responseData.user_searched;
    this.previouslySharedUsersList = responseData.users_prev_shared_with;
    this.selectedUsersList = responseData.selected_users ?? [];
    this.usersMultiSelectOptions = this.dynamicReportsUtilityService.deepCopy(this.usersOptions);
  }

  errorHandler(responseData): void {
    this.dynamicReportsUtilityService.showErrorMessage(responseData.message);
  }

  addSearchUsersHandler(): void {
    const usersToAdd = this.searchedUsersList.filter((newUser) => !this.selectedUsersList.some((existingUser) => existingUser.user_id === newUser.user_id));
    this.selectedUsersList = [...usersToAdd, ...this.selectedUsersList];
    this.shareReportFormGroup.get('searchUsers').setValue([]);
    this.searchedUsersList = [];
  }

  removeSearchedUsersHandler(user): void {
    this.searchedUsersList = this.searchedUsersList.filter((existingUser) => existingUser.user_id !== user.user_id);
    const userIds = this.searchedUsersList.map((user) => user.user_id);
    this.shareReportFormGroup.get('searchUsers').setValue(userIds);
  }

  private getSelectedUsers(ref: any): any[] {
    return ref.selectedOptions.selected.map((option) => option.value);
  }

  prevSharedUsersAddHandler(): void {
    const newUsers = this.getSelectedUsers(this.prevSharedUsersRef);
    const usersToAdd = newUsers.filter((newUser) => !this.selectedUsersList.some((existingUser) => existingUser.user_id === newUser.user_id));
    this.selectedUsersList = [...usersToAdd, ...this.selectedUsersList];
    this.prevSharedUsersRef.deselectAll();
  }

  removeSelectedUsersHandler(): void {
    const usersToRemove = this.getSelectedUsers(this.selectedUsersRef);
    this.selectedUsersList = this.selectedUsersList.filter(
      (existingUser) => !usersToRemove.some((userToRemove) => userToRemove.user_id === existingUser.user_id)
    );
    this.selectedUsersCheckAll = this.getSelectedUsers(this.selectedUsersRef).every((option) => option.selected);
  }

  selectAllUsersHandler(flag: boolean, tempRef): void {
    tempRef.options.forEach((option) => (option.selected = flag));
  }

  onChangePrevSharedUsersCheckbox(): void {
    this.prevSharedUsersCheckAll = this.prevSharedUsersRef.options.toArray().every((option) => option.selected);
  }

  onChangeSelectedUsersCheckbox(): void {
    this.selectedUsersCheckAll = this.selectedUsersRef.options.toArray().every((option) => option.selected);
  }

  onClickSendBtn(): void {
    const params = {
      report_id: this.modalData.reportId,
      user_id: this.selectedUsersList.map((user) => user.user_id),
    };
    this.dynamicReportsDialogService.saveShareReport(params).subscribe({
      next: (responseData) => {
        this.dynamicReportsUtilityService.showSuccessMessage(responseData.message);
        this.dialogRef.close();
      },
      error: this.errorHandler.bind(this),
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
