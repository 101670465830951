// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IConfig } from '../app/shared/interface/common.interface';

let environment: IConfig;

export { environment };

export function applyConfig(data: IConfig) {
  environment = data;
}
