import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ViewReportService } from '../../services/view-report.service';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { ISelectionConfigsByReportType } from '../../../../shared/interface/common.interface';
import { DROPDOWN_CONFIG } from '../../../../constants/dropdown-config.constant';
import {
  IGetReportFilterRequest,
  IGetReportFilterResponse,
  IGetSearchRequestParamsByReportType,
  IViewReportFilterToggleControls,
} from '../../interface/view-report.interface';
import * as constants from '../../../../constants/wdr.constant';
import { CONFIG_CONSTANTS, LEVEL_CONFIG } from '../../../../constants/build-reports-stepper.constant';
import { IMyReportStackedReportDetails } from '../../../../modules/my-reports/interface/my-reports.interface';
import { SpinnerService } from '../../../../shared/services/spinner.service';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss'],
})
export class ReportFilterComponent {
  @Input() sideNav: MatSidenav;
  @Output() emitReportData = new EventEmitter();
  public reportFilterFormGroup: FormGroup;
  public reportFilterDropdownResponse: IGetReportFilterResponse[] = [];
  public selectedFilterData: { date: any; item: any; comparator?: any }[] = [];

  expandedSideNav = true;
  templateId: number;
  constant = constants;
  // Stack Report Changes
  reportFilterFormGroups: FormGroup[] = [];
  stackedReportDetails: IMyReportStackedReportDetails[] = [];
  toggleFormControls: IViewReportFilterToggleControls[] = [];
  currentStep = 0;
  selectionConfigsByReportType: ISelectionConfigsByReportType[] = [];

  constructor(
    public readonly _formBuilder: FormBuilder,
    public readonly viewReportService: ViewReportService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService,
    private readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.setSelectedReportTemplateId(); // to update the templateId
    this.setStackedReportDetails(); // to update the stackedReportDetails
    this.setSelectionConfigsByReportType(); // to get the selection configs based on the report type and level id
    this.getFormGroupByReportType(); // to get the formGroup based on the report type
    this.getReportFilterData(); // to get the report filter data
  }

  getReportFilterRequestParamsByReportType(): IGetReportFilterRequest[] {
    const reqParamsArr: IGetReportFilterRequest[] = [];
    if (this.isStackedReport()) {
      this.stackedReportDetails.forEach((report: IMyReportStackedReportDetails) => {
        reqParamsArr.push({
          template_id: report.template_id,
          report_id: report.sub_report_id,
          hierarchy_id: report.hierarchy_id,
          level_id: report.level_id,
          company_id: report.company_id,
        });
      });
    } else {
      reqParamsArr.push({
        template_id: this.viewReportService.viewReportDetails?.template_id,
        report_id: this.viewReportService.viewReportDetails?.id,
        hierarchy_id: this.viewReportService.viewReportDetails?.hierarchy_id,
        level_id: this.viewReportService.viewReportDetails?.level_id,
        company_id: this.viewReportService.viewReportDetails?.company_id,
      });
    }
    return reqParamsArr;
  }

  getReportFilterData(): void {
    const reportFilterRequestParamsArr = this.getReportFilterRequestParamsByReportType();
    const reportFilterRequestApiCallsArr = reportFilterRequestParamsArr.map((params) => this.viewReportService.getReportFilter(params));
    this.getAllReportsFilterDropdownOptions(reportFilterRequestApiCallsArr);
  }

  /** This method is used to retrieve all dropdown options during Edit Report to prepopulate the form fields */
  getAllReportsFilterDropdownOptions(reportFilterRequestApiCallsArr) {
    this.viewReportService.getAllReportsFilterApiCalls(reportFilterRequestApiCallsArr).subscribe({
      next: (responses: IGetReportFilterResponse[]) => {
        this.handleGetAllReportsFilterResponse(responses);
      },
      error: (err) => {
        this.spinnerService.hideSpinner();
        this.dynamicReportsUtilityService.showErrorMessage(`${err?.message}`);
      },
    });
  }

  handleGetAllReportsFilterResponse(response: IGetReportFilterResponse[]) {
    this.reportFilterDropdownResponse = response;
  }

  selectedData(index, control, event) {
    if (!this.selectedFilterData[index]) {
      this.selectedFilterData[index] = { date: null, item: null, comparator: null };
    }
    if (control === event.controlName) {
      this.selectedFilterData[index][`${control}`] = event;
    }
  }

  /** Generating Search - Request Params */
  getSearchRequestParamsByReportType(): IGetSearchRequestParamsByReportType[] {
    const reqParamsArr: IGetSearchRequestParamsByReportType[] = [];
    if (this.isStackedReport()) {
      this.stackedReportDetails.forEach((report: IMyReportStackedReportDetails, index) => {
        const formGroup = this.reportFilterFormGroups[index];
        const levelCode = LEVEL_CONFIG[report.level_id]?.memberId;
        let memberId = this.selectedFilterData[index]['item']?.selectedOptions[0][levelCode];
        if (report.level_id === constants.LEVEL_ID.DISTRICT) {
          memberId = this.selectedFilterData[index]['item']?.selectedOptions[0]?.region_code + memberId;
        }
        reqParamsArr.push({
          report_id: report.sub_report_id,
          date: formGroup.get('date').value,
          hierarchy_id: report.hierarchy_id,
          level_id: report.level_id,
          member_id: memberId,
          datasource_id: report.datasource_id,
        });
      });
    } else {
      const formGroup = this.reportFilterFormGroups[0];
      const levelCode = LEVEL_CONFIG[this.viewReportService.viewReportDetails?.level_id]?.memberId;
      let memberId = this.selectedFilterData[0]['item']?.selectedOptions[0][levelCode];
      if (this.viewReportService.viewReportDetails?.level_id === constants.LEVEL_ID.DISTRICT) {
        memberId = this.selectedFilterData[0]['item']?.selectedOptions[0]?.region_code + memberId;
      }
      reqParamsArr.push({
        report_id: this.viewReportService.viewReportDetails?.id,
        date: formGroup.get('date').value,
        hierarchy_id: this.viewReportService.viewReportDetails?.hierarchy_id,
        level_id: this.viewReportService.viewReportDetails?.level_id,
        member_id: memberId,
        datasource_id: this.viewReportService.viewReportDetails?.datasource_id,
      });
    }
    return reqParamsArr;
  }

  onSearch() {
    this.viewReportService.viewReportTableData = null;
    this.viewReportService.viewFilteredData = this.selectedFilterData;
    const searchRequestParamsArr = this.getSearchRequestParamsByReportType();
    const searchRequestApiCallsArr = searchRequestParamsArr.map((params) => this.viewReportService.getViewReport(params));
    this.getAllReportsViewOrTableData(searchRequestApiCallsArr);
  }

  getAllReportsViewOrTableData(searchRequestApiCallsArr): void {
    this.viewReportService.getAllReportsViewOrTableData(searchRequestApiCallsArr).subscribe({
      next: (responses) => {
        this.handleGetAllReportsViewOrTableDataResponse(responses);
      },
      error: (err) => {
        this.spinnerService.hideSpinner();
        this.dynamicReportsUtilityService.showErrorMessage(`${err?.message}`);
      },
    });
  }

  handleGetAllReportsViewOrTableDataResponse(responses): void {
    this.emitReportData.emit(responses);
  }

  /** ***** Stack Report Changes ***** */

  getFilterDropdownConfigByLevelId(dropdownControl, levelId: number) {
    const REPORT_FILTER_CONTROL = JSON.parse(JSON.stringify(DROPDOWN_CONFIG.REPORT_FILTER_CONTROL));
    for (const field in CONFIG_CONSTANTS) {
      this.viewReportService.getDealerTableConfig(dropdownControl, levelId, this.viewReportService.viewReportDetails.hierarchy_id);
      REPORT_FILTER_CONTROL[dropdownControl][CONFIG_CONSTANTS[field]] = LEVEL_CONFIG?.[levelId]?.[CONFIG_CONSTANTS[field]];
    }
    return REPORT_FILTER_CONTROL;
  }

  handleSideNavToggle(): void {
    this.expandedSideNav = !this.expandedSideNav;
    this.sideNav?.toggle();
  }

  onClickExpandBtn(index, fieldName): void {
    this.toggleFormControls[index][`toggle${fieldName}`] = !this.toggleFormControls[index][`toggle${fieldName}`];
  }

  onClickClearBtn(formGroup: FormGroup, controlName): void {
    formGroup.get(controlName)?.setValue('');
    formGroup.get(controlName)?.reset();
    this.viewReportService.viewReportTableData = null;
  }

  getCommonFormGroup(): FormGroup {
    return this._formBuilder.group({
      date: ['', Validators.required],
      item: ['', Validators.required],
    });
  }

  getExternalComparisonFormGroup(): FormGroup {
    return this._formBuilder.group({
      date: ['', Validators.required],
      dealer: ['', Validators.required],
      comparator: ['', Validators.required],
    });
  }

  addFormToReportFilterFormGroups(formGroup: FormGroup): void {
    this.addToggleFormControlsFlags(); // Add Toggle Form Controls Flags according to the number of formGroups
    this.reportFilterFormGroups.push(formGroup);
  }

  addFormByTemplateId(templateId): void {
    if (templateId === this.constant.TEMPLATE_ID.EXTERNAL_COMPARISON) {
      this.addFormToReportFilterFormGroups(this.getExternalComparisonFormGroup());
    } else {
      this.addFormToReportFilterFormGroups(this.getCommonFormGroup());
    }
  }

  setSelectedReportTemplateId(): void {
    this.templateId = this.viewReportService.viewReportDetails?.template_id;
  }

  setStackedReportDetails(): void {
    if (this.isStackedReport()) {
      this.stackedReportDetails = this.viewReportService.viewReportDetails?.stackedReportDetails || [];
    }
  }

  addItemToSelectionConfigsByReportType(selectionConfigs: ISelectionConfigsByReportType): void {
    this.selectionConfigsByReportType.push(selectionConfigs);
  }

  getSelectionConfigsByReportType(templateId: number, levelId: number): ISelectionConfigsByReportType {
    const selectionConfigs: ISelectionConfigsByReportType = {
      dateFilterConfig: JSON.parse(JSON.stringify(DROPDOWN_CONFIG.REPORT_FILTER_CONTROL.DATE_CONTROL)),
      itemFilterConfig: this.getFilterDropdownConfigByLevelId('ITEM_CONTROL', levelId).ITEM_CONTROL,
    };
    if (templateId === this.constant.TEMPLATE_ID.EXTERNAL_COMPARISON) {
      selectionConfigs.comparatorFilterConfig = this.getFilterDropdownConfigByLevelId('COMPARATOR_CONTROL', levelId).COMPARATOR_CONTROL;
    }
    return selectionConfigs;
  }

  setSelectionConfigsByReportType(): void {
    if (this.isStackedReport()) {
      this.stackedReportDetails.forEach((report: IMyReportStackedReportDetails) => {
        const selectionConfigs = this.getSelectionConfigsByReportType(report.template_id, report.level_id);
        this.addItemToSelectionConfigsByReportType(selectionConfigs);
      });
    } else {
      const selectionConfigs = this.getSelectionConfigsByReportType(this.templateId, this.viewReportService.viewReportDetails?.level_id);
      this.addItemToSelectionConfigsByReportType(selectionConfigs);
    }
  }

  getFormGroupByReportType(): void {
    if (this.isStackedReport()) {
      this.stackedReportDetails.forEach((report: IMyReportStackedReportDetails) => {
        this.addFormByTemplateId(report.template_id);
      });
    } else {
      this.addFormByTemplateId(this.templateId);
    }
  }

  isStackedReport(): boolean {
    return this.templateId === this.constant.TEMPLATE_ID.STACK;
  }

  getStackedReportDetailsByIndex(index: number): IMyReportStackedReportDetails {
    return this.stackedReportDetails[index];
  }

  areAllFormsValid(): boolean {
    return this.reportFilterFormGroups.every((formGroup) => formGroup.valid);
  }

  addToggleFormControlsFlags(): void {
    this.toggleFormControls.push({ toggleDate: true, toggleItem: true, toggleComparator: true });
  }

  nextStep() {
    if (this.currentStep < this.reportFilterFormGroups.length - 1) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }
}
