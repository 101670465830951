import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ViewReportContainerComponent } from './components/view-report-container/view-report-container.component';
import { ReportFilterComponent } from './components/report-filter/report-filter.component';
import { ReportContentComponent } from './components/report-content/report-content.component';

@NgModule({
  declarations: [ViewReportContainerComponent, ReportFilterComponent, ReportContentComponent],
  imports: [CommonModule, SharedModule],
})
export class ViewReportModule {}
