import { Component } from '@angular/core';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { BuildReportsStepperDialogComponent } from '../../../../shared/components/build-reports-stepper-dialog/build-reports-stepper-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { IReportTemplate } from '../../interface/build-report.interface';
import { REPORT_ACTION } from '../../../../constants/wdr.constant';
import { BuildReportsService } from '../../services/build-reports.service';

@Component({
  selector: 'app-build-reports-dashboard',
  templateUrl: './build-reports-dashboard.component.html',
  styleUrls: ['./build-reports-dashboard.component.scss'],
})
export class BuildReportsDashboardComponent {
  dialogRef: MatDialogRef<any>;
  reportTemplates: IReportTemplate[];
  allowedTemplates = ['Hierarchical', 'Comparison', 'ExternalComparison', 'Historical'];

  constructor(private readonly dynamicReportsUtilityService: DynamicReportsUtilityService, private readonly buildReportsService: BuildReportsService) {}

  ngOnInit(): void {
    this.checkReportTemplates();
  }

  checkReportTemplates(): void {
    this.reportTemplates = this.buildReportsService.reportTemplates;
    if (!this.reportTemplates) {
      this.getReportTemplates();
    }
  }

  getReportTemplates(): void {
    this.buildReportsService.getReportTemplates().subscribe({
      next: (responseData) => {
        this.buildReportsService.reportTemplates = responseData;
        this.reportTemplates = responseData;
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData);
      },
    });
  }

  onclickReportTemplate(reportTemplate: IReportTemplate): void {
    this.dialogRef = this.dynamicReportsUtilityService.openDialog(BuildReportsStepperDialogComponent, {
      data: { reportType: reportTemplate, reportAction: REPORT_ACTION.CREATE },
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
