<div id="dxfw-wdr-build-reports-stepper-container" class="dxfw-wdr-mat-select-container">
  <mat-stepper [linear]="isLinear" #stepper [animationDuration]="'0'">
    <!-- Report Detail Section -->
    <mat-step [stepControl]="reportDetailsFormGroup">
      <app-stepper-header [stepperDetails]="stepperHeader.reportDetails"></app-stepper-header>
      <div class="dxfw-wdr-build-reports-stepper-content" mat-dialog-content>
        <app-report-details [reportDetailsFormGroup]="reportDetailsFormGroup"></app-report-details>
      </div>
      <div class="dxfw-wdr-build-reports-stepper-footer">
        <button class="secondary-button action" (click)="closeDialog()">Cancel</button>
        <button matStepperNext class="primary-button action float-right">Next</button>
      </div>
    </mat-step>
    <!-- Report Criteria Section -->
    <mat-step [stepControl]="reportCriteriaFormGroup">
      <app-stepper-header [stepperDetails]="stepperHeader.reportCriteria"></app-stepper-header>
      <div class="dxfw-wdr-build-reports-stepper-content" mat-dialog-content>
        <app-report-criteria
          [reportCriteriaResponseData]="reportCriteriaResponseData"
          [reportCriteriaFormGroup]="reportCriteriaFormGroup"></app-report-criteria>
      </div>
      <div class="dxfw-wdr-build-reports-stepper-footer">
        <button class="secondary-button action" (click)="closeDialog()">Cancel</button>
        <button matStepperNext class="primary-button action float-right" (click)="onClickReportCriteriaNextBtn()">Next</button>
        <button matStepperPrevious class="secondary-button action float-right m-r-10">Previous</button>
      </div>
    </mat-step>
    <!-- Report Column & Table Section -->
    <mat-step [stepControl]="reportColumnFormGroup" [optional]="'true'">
      <app-stepper-header [stepperDetails]="stepperHeader.reportColumns"></app-stepper-header>
      <div class="dxfw-wdr-build-reports-stepper-content" mat-dialog-content>
        <app-report-columns
          [reportColumnFormGroup]="reportColumnFormGroup"
          [reportCriteriaFormData]="reportCriteriaFormData"
          [reportAction]="modalData.reportAction"
          [selectedFieldsTableDataSource]="selectedFieldsTableDataSource"></app-report-columns>
      </div>
      <div class="dxfw-wdr-build-reports-stepper-footer">
        <button class="secondary-button action" (click)="closeDialog()">Cancel</button>
        <button class="primary-button float-right m-r-10" (click)="onClickGenerateReport()" [disabled]="!selectedFieldsTableDataSource.length">
          Generate Report
        </button>
        <button matStepperPrevious class="secondary-button action float-right m-r-10">Previous</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
