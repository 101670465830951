import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ViewReportService } from '../../services/view-report.service';
import { DynamicReportsUtilityService } from '../../../../shared/services/dynamic-reports-utility.service';
import { ISelectionConfig } from '../../../../shared/interface/common.interface';
import { DROPDOWN_CONFIG } from '../../../../constants/dropdown-config.constant';
import { IGetReportFilterRequest, IGetReportFilterResponse } from '../../interface/view-report.interface';
import * as constants from '../../../../constants/wdr.constant';

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss'],
})
export class ReportFilterComponent {
  @Input() sideNav: MatSidenav;
  @Output() emitReportData = new EventEmitter();
  public reportFilterFormGroup: FormGroup;
  public reportFilterDropdownResponse: IGetReportFilterResponse;
  public selectedFilterData = {};

  expandedSideNav = true;
  reportFilterDate = true;
  reportFilterItem = true;
  reportFilterComparator = true;
  templateId: number;
  constant = constants;

  dateFilterConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_FILTER_CONTROL.DATE_CONTROL as ISelectionConfig;
  itemFilterConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_FILTER_CONTROL.ITEM_CONTROL as ISelectionConfig;
  comparatorFilterConfig: ISelectionConfig = DROPDOWN_CONFIG.REPORT_FILTER_CONTROL.COMPARATOR_CONTROL as ISelectionConfig;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly viewReportService: ViewReportService,
    private readonly dynamicReportsUtilityService: DynamicReportsUtilityService
  ) {}

  ngOnInit(): void {
    this.reportFilterFormGroup = this._formBuilder.group({
      date: ['', Validators.required],
      item: ['', Validators.required],
    });
    this.getReportFilterData();
    if (this.templateId === this.constant.TEMPLATE_ID.EXTERNAL_COMPARISON) {
      this.reportFilterFormGroup.addControl('comparator', this._formBuilder.control('', Validators.required));
      this.viewReportService.setComparatorCriteria();
      this.comparatorFilterConfig = DROPDOWN_CONFIG.REPORT_FILTER_CONTROL.COMPARATOR_CONTROL as ISelectionConfig;
    }
  }

  getReportFilterData(): void {
    const reqParams: IGetReportFilterRequest = {
      template_id: this.viewReportService.viewReportDetails?.template_id,
      report_id: this.viewReportService.viewReportDetails?.id,
      hierarchy_id: this.viewReportService.viewReportDetails?.hierarchy_id,
      level_id: this.viewReportService.viewReportDetails?.level_id,
      company_id: this.viewReportService.viewReportDetails?.company_id,
    };
    this.templateId = this.viewReportService.viewReportDetails?.template_id;
    this.viewReportService.getReportFilter(reqParams).subscribe({
      next: (responseData) => {
        this.reportFilterDropdownResponse = responseData;
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData);
      },
    });
  }

  handleSideNavToggle(): void {
    this.expandedSideNav = !this.expandedSideNav;
    this.sideNav?.toggle();
  }

  onClickExpandBtn(fieldName): void {
    this['reportFilter' + fieldName] = !this['reportFilter' + fieldName];
  }

  onClickClearBtn(fieldName): void {
    this.reportFilterFormGroup.get(fieldName).setValue('');
    this.reportFilterFormGroup.get(fieldName).reset();
    this.viewReportService.viewReportTableData = null;
  }

  selectedData(control, event) {
    if (control === event.controlName) {
      this.selectedFilterData[control] = event;
    }
  }

  onSearch() {
    this.viewReportService.viewReportTableData = null;
    this.viewReportService.viewFilteredData = this.selectedFilterData;
    const Params = {
      report_id: this.viewReportService.viewReportDetails?.id,
      date: this.reportFilterFormGroup.get('date').value,
      hierarchy_id: this.viewReportService.viewReportDetails?.hierarchy_id,
      level_id: this.viewReportService.viewReportDetails?.level_id,
      member_id: 4136,
    };
    this.viewReportService.getHierarchicalFinancialReport(Params).subscribe({
      next: (responseData) => {
        this.emitReportData.emit(responseData);
      },
      error: (responseData) => {
        this.dynamicReportsUtilityService.showErrorMessage(responseData);
      },
    });
  }
}
