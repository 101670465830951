import { Injectable } from '@angular/core';
import { jsPDF, jsPDFOptions } from 'jspdf';
import autoTable, { CellHookData, Color, Styles, UserOptions } from 'jspdf-autotable';
import { SpinnerService } from '../../../shared/services/spinner.service';
import { TEMPLATE_ID } from '../../../constants/wdr.constant';
import { IViewReportExportDocData } from '../interface/view-report.interface';

@Injectable({
  providedIn: 'root',
})
export class ViewReportExportPdfService {
  private endYCoord = 15;

  constructor(private readonly spinnerService: SpinnerService) {}

  getEndYCoord(): number {
    return this.endYCoord;
  }

  setInitialValueToEndYCoord(): void {
    this.endYCoord = 15;
  }

  updateEndYCoord(newYCoord: number): number {
    this.endYCoord += newYCoord;
    return this.endYCoord;
  }

  getDefaultPDFOptions(customPDFOptions = {}): jsPDFOptions {
    const defaultPDFOptions: jsPDFOptions = {
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    };
    return { ...defaultPDFOptions, ...customPDFOptions };
  }

  getExportDocFileName(propsToExportPdf): string {
    if (propsToExportPdf.isStackedReport) {
      return `${propsToExportPdf.viewReportDetails.report_title}_${propsToExportPdf.reportData[0].date}_${Date.now()}`;
    }
    return `${propsToExportPdf.reportData[0].title}_${propsToExportPdf.reportData[0].date}_${Date.now()}`;
  }

  setFontStyle(doc: jsPDF): void {
    doc.setFont('helvetica', 'normal', 'bold');
    doc.setFontSize(7);
  }

  setDocumentHeader(doc: jsPDF, propsToExportPdf: IViewReportExportDocData, index): void {
    const header = propsToExportPdf.reportData[index];
    if (index !== 0) {
      const newYCoord = (doc as any).lastAutoTable.finalY + 5;
      this.updateEndYCoord(newYCoord - this.getEndYCoord());
    }
    const pageWidth = doc.internal.pageSize.getWidth();
    if (propsToExportPdf.isStackedReport) {
      if (index === 0) {
        doc.text(`${propsToExportPdf.viewReportDetails.report_title}`.toUpperCase(), 10, this.getEndYCoord(), { align: 'left' });
        this.updateEndYCoord(5);
      }
      doc.text(header.title.toUpperCase(), 10, this.updateEndYCoord(5), { align: 'left' });
      doc.text(`DATE: (${header.date.toString().toUpperCase()})`, pageWidth - 20, this.getEndYCoord(), { align: 'right' });
      doc.text(header.tableCaption, pageWidth / 2, this.updateEndYCoord(5), { align: 'center' });
    } else {
      doc.text(header.title.toUpperCase(), 10, this.getEndYCoord(), { align: 'left' });
      doc.text(`DATE: (${header.date.toString().toUpperCase()})`, pageWidth - 20, this.getEndYCoord(), { align: 'right' });
      doc.text(header.tableCaption, pageWidth / 2, this.updateEndYCoord(5), { align: 'center' });
    }
  }

  configTableBodySectionByTemplateId(data: CellHookData, _table: HTMLTableElement, reportData): void {
    const columnIndex = data.column.index;
    const rowIndex = data.row.index;
    data.cell.styles.valign = 'middle';
    this.setCellAlignment(data, reportData, columnIndex);
    if (data.section === 'body') {
      this.setBodySectionStyles(data, rowIndex);
      if (reportData.template_id === TEMPLATE_ID.HIERARCHICAL) {
        this.setHierarchicalReportStyles(data, _table, columnIndex, rowIndex);
      }
      const noResultCell = _table.querySelector('.dxfw-wdr-view-report-table-no-result');
      if (noResultCell) {
        data.cell.styles = { textColor: '#CC0000', halign: 'center', valign: 'middle', minCellHeight: 6, fontSize: 6, fontStyle: 'normal' } as Styles;
      }
    }
  }

  setCellAlignment(data: CellHookData, reportData, columnIndex: number): void {
    if (reportData.template_id === TEMPLATE_ID.HIERARCHICAL) {
      data.cell.styles.halign = columnIndex < 2 ? 'left' : 'right'; // Align the first two cells to the left
    } else if (reportData.template_id === TEMPLATE_ID.COMPARISON) {
      data.cell.styles.halign = columnIndex < 1 ? 'left' : 'right';
      if (data.section === 'head') {
        data.cell.styles.halign = 'center';
      }
    }
  }

  setBodySectionStyles(data: CellHookData, rowIndex: number): void {
    if ((rowIndex + 1) % 2 === 0) {
      data.cell.styles.fillColor = [240, 240, 240]; // set color for even rows
    }
  }

  setHierarchicalReportStyles(data: CellHookData, _table: HTMLTableElement, columnIndex: number, rowIndex: number): void {
    const rowElement = _table.querySelector(`tbody tr:nth-child(${rowIndex + 1})`);
    if (rowElement) {
      const backgroundColor = (rowElement as HTMLElement).style.backgroundColor;
      if (backgroundColor) {
        data.cell.styles.fillColor = backgroundColor as Color;
      }
    }
    if (columnIndex === 1) {
      data.cell.styles.cellWidth = 55;
    }
  }

  getAutoTableUserOptions(_doc, _table: HTMLTableElement, reportData, index): UserOptions {
    const endYCoord = this.updateEndYCoord(2);
    const tableLineColor: Color = [154, 144, 100];
    const tableCellLineColor: Color = [229, 235, 235];
    const commonStyles: Partial<Styles> = {
      halign: 'center',
      textColor: [51, 51, 51],
      lineColor: tableLineColor,
      lineWidth: 0.1,
    };
    const headStyles: Partial<Styles> = {
      fillColor: [199, 193, 169],
      lineColor: tableLineColor,
      lineWidth: 0.01,
      minCellHeight: 6,
      fontSize: 6,
    };
    const bodyStyles: Partial<Styles> = {
      minCellHeight: 6, // Increase row height
      fontSize: 5,
      lineColor: tableCellLineColor,
    };
    const customTableStyleOptions: UserOptions = {
      html: '#dxfw-wdr-view-report-table' + index, // table id
      theme: 'grid',
      startY: endYCoord,
      tableLineWidth: 0.1,
      styles: commonStyles,
      headStyles: headStyles,
      bodyStyles: bodyStyles,
      columnStyles: {
        0: { halign: 'left' },
      },
      pageBreak: 'auto',
      margin: { left: 10 },
      didParseCell: (data: CellHookData) => {
        this.configTableBodySectionByTemplateId(data, _table, reportData);
      },
      didDrawCell: (data: CellHookData) => {
        data.cell.styles.valign = 'middle';
      },
    };
    return customTableStyleOptions;
  }

  printTables(doc: jsPDF, propsToExportPdf: IViewReportExportDocData): void {
    const tables = document.querySelectorAll('table'); // select tables
    tables.forEach((_table: HTMLTableElement, index: number) => {
      this.setDocumentHeader(doc, propsToExportPdf, index);
      const autoTableUserOptions = this.getAutoTableUserOptions(doc, _table, propsToExportPdf.reportData[index], index);
      autoTable(doc, autoTableUserOptions);
    });
  }

  addPageNumber(doc: jsPDF): void {
    const totalPages = doc.getNumberOfPages();
    const shortDate = new Date().toLocaleDateString();
    const pageHeight = doc.internal.pageSize.getHeight() - 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.text(`Printed On: ${shortDate}`, 10, pageHeight, { align: 'left' });
      doc.text(`Page ${i} of ${totalPages}`, pageWidth - 10, pageHeight, { align: 'right' });
    }
  }

  savePdf(doc: jsPDF, fileName: string): void {
    doc.save(`${fileName}.pdf`);
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
  }

  async exportToPdf(propsToExportPdf: IViewReportExportDocData): Promise<void> {
    this.spinnerService.displaySpinner();
    this.setInitialValueToEndYCoord();
    const pdfOptions = this.getDefaultPDFOptions();
    const fileName = this.getExportDocFileName(propsToExportPdf);
    const doc = new jsPDF(pdfOptions);
    this.setFontStyle(doc);
    this.printTables(doc, propsToExportPdf);
    this.addPageNumber(doc);
    this.savePdf(doc, fileName);
    this.spinnerService.hideSpinner();
  }
}
